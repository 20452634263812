import { Components } from "@mui/material";

const components: Components = {
  MuiAlertTitle: {
    styleOverrides: {
      root: {
        lineHeight: "150%",
        letterSpacing: "0px",
        fontSize: "16px",
      },
    },
  },
  MuiAvatar: {
    styleOverrides: {
      root: {
        lineHeight: "100%",
        letterSpacing: 0.14,
        fontSize: "20px",
      },
    },
  },
  MuiBadge: {
    styleOverrides: {
      root: {
        lineHeight: "166%",
        letterSpacing: "0px",
        fontSize: "12px",
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        lineHeight: "170%",
        letterSpacing: "0px",
        borderRadius: "8px",
      },
      sizeSmall: {
        fontSize: "13px",
      },
      sizeMedium: {
        fontSize: "14px",
      },
      sizeLarge: {
        fontSize: "15px",
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        fontSize: "16px",
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        lineHeight: "166%",
        letterSpacing: "0px",
        fontSize: "12px",
      },
    },
  },
  MuiInput: {
    styleOverrides: {
      root: {
        lineHeight: "150%",
        letterSpacing: "0px",
        fontSize: "16px",
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        lineHeight: "140%",
        letterSpacing: "0px",
        fontSize: "13px",
        borderRadius: "8px",
      },
    },
  },
  MuiTableHead: {
    styleOverrides: {
      root: {
        lineHeight: "170%",
        letterSpacing: "0px",
        fontSize: "14px",
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        lineHeight: "150%",
        letterSpacing: "0px",
        fontSize: "16px",
      },
      dense: {
        lineHeight: "170%",
        fontSize: "14px",
      },
    },
  },
  MuiListSubheader: {
    styleOverrides: {
      root: {
        lineHeight: "342%",
        letterSpacing: "0px",
        fontSize: "14px",
      },
    },
  },
  MuiBottomNavigation: {
    styleOverrides: {
      root: {
        lineHeight: "166%",
        letterSpacing: "0px",
        fontSize: "14px",
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: "8px",
      },
    },
  },
};

export default components;
