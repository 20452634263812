import { styled, Typography } from "@helo/ui";
import ContactSupportBtn from "~/components/contact-support-btn";

const Container = styled("section")(({ theme }) => ({
  margin: 0,
  padding: "50px 25px",
  backgroundColor: theme.palette.accentPurple.dark,
  color: theme.palette.primary.contrastText,
  textAlign: "center",
}));

const PreFooter = () => (
  <Container>
    <Typography variant="h6">Have Questions?</Typography>
    <ContactSupportBtn />
  </Container>
);

export default PreFooter;
