import { Button, HelpIcon } from "@helo/ui";
import { openWebWidget } from "~/helpers/frontapp";

const ContactSupportBtn = () => {
  return (
    <Button
      variant="contained"
      size="large"
      className="button-purple--inverted"
      onClick={openWebWidget}
      sx={{ mt: 1 }}
    >
      <HelpIcon sx={{ mr: 1.5 }} />
      <span>Contact Support</span>
    </Button>
  );
};

export default ContactSupportBtn;
