import { NextPage } from "next";
import Script from "next/script";
import { Toaster } from "react-hot-toast";
import { Box } from "@helo/ui";

import Header from "~/components/header";
import PreFooter from "~/components/pre-footer";
import Footer from "~/components/footer";

declare global {
  interface Window {
    FrontChat: any;
  }
}

const Layout: NextPage = ({ children }) => {
  return (
    <>
      <Header />
      <Box
        component="main"
        sx={{ minHeight: "100vh", overflow: "hidden", position: "relative" }}
      >
        {children}
      </Box>
      <PreFooter />
      <Footer />
      {/* Global notification toast */}
      <Toaster
        toastOptions={{
          className: "react-hot-toast",
        }}
      />
      <Script
        id="front-widget"
        src="https://chat-assets.frontapp.com/v1/chat.bundle.js"
        onLoad={() => {
          window.FrontChat("init", {
            chatId: "fd5f6512a706a051d303878c7fb9edf0",
            useDefaultLauncher: true,
          });
        }}
      />
    </>
  );
};

export default Layout;
