import { Color } from "@mui/material";

const primaryScale = {
  50: "#031A6B",
  100: "#033082",
  200: "#0251A5",
  300: "#0072C7",
  400: "#1295EE",
  500: "#18A0FB",
  600: "#6DD1FF",
  700: "#A2DFFF",
  800: "#D7ECFE",
  900: "#ECF6FE",
  1000: "#F6FAFE",
};
const primary = {
  main: primaryScale[500],
  dark: primaryScale[300],
  light: primaryScale[600],
  contrastText: primaryScale[1000],
};
const secondary = {
  main: primaryScale[50],
  dark: "#000040",
  light: "#44419A",
  contrastText: "#FFFFFF",
};
const accentCyan = {
  main: "#9FEDF5",
  dark: "#91D7DF",
  light: "#BEF2F9",
  contrastText: primaryScale[100],
};
const accentPurple = {
  main: "#44419A",
  dark: "#38367F",
  light: "#6462AB",
  contrastText: primary.contrastText,
};
const accentIndigo = {
  main: "#D0D0FF",
  dark: "#BDBDE8",
  light: "#DCDCFF",
  contrastText: primaryScale[100],
};
const accentGreen = {
  main: "#66BB6A",
  dark: "#388E3C",
  light: "#81C784",
  contrastText: secondary.dark,
};
const accentRed = {
  main: "#D5190B",
  dark: "#9B1208",
  light: "#F65C51",
  contrastText: primary.contrastText,
};
const accentYellow = {
  main: "#FFB400",
  dark: "#E8A400",
  light: "#FFD160",
  contrastText: secondary.dark,
};
const neutralScale = {
  100: "#0C1218",
  200: "#1A242E",
  300: "#2A3641",
  400: "#3D4751",
  500: "#6F7880",
  600: "#9DA3AA",
  700: "#C9CFD4",
  800: "#DEE3E8",
  900: "#EBF0F4",
  1000: "#F6FAFE",
};
const neutral = {
  main: neutralScale[700],
  dark: neutralScale[500],
  darker: neutralScale[100],
  light: neutralScale[900],
  lighter: neutralScale[1000],
  contrastText: neutralScale[300],
};
const info = accentPurple;
const warning = accentYellow;
const error = accentRed;
const success = accentGreen;

const palette = {
  primaryScale,
  primary,
  secondary,
  info,
  warning,
  error,
  success,
  accentCyan,
  accentPurple,
  accentIndigo,
  accentGreen,
  accentRed,
  accentYellow,
  neutralScale,
  neutral,
};

declare module "@mui/material/styles" {
  interface Palette {
    primaryScale: Partial<Color>;
    accentCyan: Palette["primary"];
    accentPurple: Palette["primary"];
    accentIndigo: Palette["primary"];
    accentGreen: Palette["primary"];
    accentRed: Palette["primary"];
    accentYellow: Palette["primary"];
    neutralScale: Partial<Color>;
    neutral: Palette["primary"];
  }

  interface PaletteOptions {
    primaryScale: Partial<Color>;
    accentCyan: Palette["primary"];
    accentPurple: Palette["primary"];
    accentIndigo: Palette["primary"];
    accentGreen: Palette["primary"];
    accentRed: Palette["primary"];
    accentYellow: Palette["primary"];
    neutralScale: Partial<Color>;
    neutral: Palette["primary"];
  }

  interface PaletteColor {
    lightest?: string;
    lighter?: string;
    darker?: string;
    darkest?: string;
  }
  interface SimplePaletteColorOptions {
    lightest?: string;
    lighter?: string;
    darker?: string;
    darkest?: string;
  }

  interface Theme {
    palette: Palette;
  }

  interface ThemeOptions {
    palette?: PaletteOptions;
  }

  interface Color {
    50: string;
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
    1000: string;
    A100: string;
    A200: string;
    A400: string;
    A700: string;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    accentCyan: true;
    accentPurple: true;
    accentIndigo: true;
    accentGreen: true;
    accentRed: true;
    accentYellow: true;
    neutral: true;
  }
}

declare module "@mui/material/Chip" {
  interface ChipPropsColorOverrides {
    accentCyan: true;
    accentPurple: true;
    accentIndigo: true;
    accentGreen: true;
    accentRed: true;
    accentYellow: true;
    neutral: true;
  }
}

export default palette;
