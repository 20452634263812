import {
  Link,
  Button,
  styled,
  Container,
  Box,
  Typography,
  Divider,
} from "@helo/ui";
import SwayLogoSvg from "@helo/ui/assets/images/sway-logo-purple.svg";
import NextLink from "next/link";

import { AppName, BrandName } from "~/constants/brand";

const HeaderContainer = styled("header")(
  ({ theme: { palette, breakpoints, zIndex, shadows, spacing } }) => ({
    position: "relative",
    zIndex: zIndex.appBar,
    height: "84px",
    backgroundColor: palette.secondary.contrastText,
    boxShadow: shadows[1],
    paddingInline: spacing(2),
    paddingBlock: spacing(0.5),
    [breakpoints.up("sm")]: {
      height: "64px",
    },
  }),
);

const HeaderWrapper = styled(Container)({
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  justifyContent: "space-between",
  height: "100%",
  width: "100%",
});

const Banner = styled(Box)(({ theme: { palette, breakpoints, zIndex } }) => ({
  display: "block",
  width: "100%",
  minHeight: "30px",
  backgroundColor: palette.secondary.light,
  position: "relative",
  zIndex: zIndex.appBar,
  [breakpoints.up("sm")]: { display: "none" },
}));

const Logo = styled("img")({
  width: "130px",
});

const Header = () => {
  return (
    <>
      <Banner>
        <Link
          href="https://www.shipsway.com"
          rel="noopener noreferrer"
          target="_blank"
          variant="body2"
          sx={({ palette }) => ({
            display: "inline-block",
            width: "100%",
            textAlign: "center",
            color: palette.neutralScale[900],
            textDecorationColor: palette.neutralScale[900],
          })}
        >
          Back to shipsway.com
        </Link>
      </Banner>
      <HeaderContainer>
        <HeaderWrapper>
          <Box
            sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          >
            <NextLink href="/">
              <Logo src={SwayLogoSvg.src} alt={BrandName} />
            </NextLink>
            <Divider
              orientation="vertical"
              sx={({ palette }) => ({
                borderColor: palette.neutralScale[600],
                mx: 1,
                height: 20,
              })}
            />
            <Typography
              variant="subtitle1"
              color="secondary"
              sx={{ fontWeight: 300 }}
            >
              {AppName}
            </Typography>
          </Box>
          <Button
            variant="outlined"
            href="https://www.shipsway.com"
            target="_blank"
            rel="noopener noreferrer"
            sx={({ breakpoints }) => ({
              display: "none",
              [breakpoints.up("sm")]: {
                display: "inline-flex",
                fontSize: "0.8rem",
                py: 0.75,
                px: 2,
              },
            })}
          >
            Back to shipsway.com
          </Button>
        </HeaderWrapper>
      </HeaderContainer>
    </>
  );
};

export default Header;
