import { createTheme, ThemeProvider } from "@mui/material/styles";
import type { ThemeOptions } from "@mui/material";

import palette from "./colorPalette";
import breakpoints from "./breakpoints";
import components from "./components";
import typography from "./typography";

const themeOptions: ThemeOptions = {
  breakpoints,
  palette,
  components,
  typography,
};
const defaultTheme = createTheme(themeOptions);

export type { Theme, ThemeOptions } from "@mui/material";
export { themeOptions, defaultTheme, ThemeProvider, createTheme };
