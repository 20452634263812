import type { AppProps } from "next/app";
import { ThemeProvider, defaultTheme } from "@helo/ui";
import { Router } from "next/router";
import nProgress from "nprogress";
import Head from "next/head";

import "~/assets/scss/app.scss";
import Layout from "~/layouts/app";
import { AppName, BrandName } from "~/constants/brand";

Router.events.on("routeChangeStart", nProgress.start);
Router.events.on("routeChangeError", nProgress.done);
Router.events.on("routeChangeComplete", nProgress.done);

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <title>
          {AppName} | {BrandName}
        </title>
        <meta
          name="description"
          content="If you have a tracking number provided by Swyft, use our package tracking app to see the most up-to-date delivery information about your package."
        />
        <meta name="theme-color" content="#031A6B" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, viewport-fit=cover"
        />
      </Head>
      <ThemeProvider theme={defaultTheme}>
        <Layout>
          <Component {...pageProps} />
        </Layout>
      </ThemeProvider>
    </>
  );
}

export default MyApp;
