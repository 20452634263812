import { TypographyVariantsOptions } from "@mui/material";
import breakpoints from "./breakpoints";

const baseFontSize = 14;
const getRem = (fontSize: number) => `${fontSize / baseFontSize}rem`;

const typography: TypographyVariantsOptions = {
  allVariants: {
    fontFamily: [
      "Sofia Pro",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      "Oxygen-Sans",
      "Ubuntu",
      "Cantarell",
      '"Helvetica Neue"',
      "sans-serif",
    ].join(","),
  },
  h1: {
    lineHeight: "116.7%",
    letterSpacing: "0px",
    fontSize: getRem(86),
    [`@media (min-width: ${breakpoints.values.sm}px)`]: {
      fontSize: getRem(96),
    },
  },
  h2: {
    lineHeight: "120%",
    letterSpacing: "0px",
    fontSize: getRem(50),
    [`@media (min-width: ${breakpoints.values.sm}px)`]: {
      fontSize: getRem(60),
    },
  },
  h3: {
    lineHeight: "116.7%",
    letterSpacing: "0px",
    fontSize: getRem(44),
    [`@media (min-width: ${breakpoints.values.sm}px)`]: {
      fontSize: getRem(48),
    },
  },
  h4: {
    lineHeight: "123.5%",
    letterSpacing: "0px",
    fontSize: getRem(30),
    [`@media (min-width: ${breakpoints.values.sm}px)`]: {
      fontSize: getRem(34),
    },
  },
  h5: {
    lineHeight: "133.4%",
    letterSpacing: "0px",
    fontSize: getRem(20),
    [`@media (min-width: ${breakpoints.values.sm}px)`]: {
      fontSize: getRem(24),
    },
  },
  h6: {
    lineHeight: "160%",
    letterSpacing: "0px",
    fontSize: getRem(18),
    [`@media (min-width: ${breakpoints.values.sm}px)`]: {
      fontSize: getRem(20),
    },
  },
  body1: {
    lineHeight: "150%",
    letterSpacing: "0px",
    fontSize: getRem(14),
    [`@media (min-width: ${breakpoints.values.sm}px)`]: {
      fontSize: getRem(16),
    },
  },
  body2: {
    lineHeight: "143%",
    letterSpacing: "0px",
    fontSize: getRem(12),
    [`@media (min-width: ${breakpoints.values.sm}px)`]: {
      fontSize: getRem(14),
    },
  },
  subtitle1: {
    lineHeight: "175%",
    letterSpacing: "0px",
    fontSize: getRem(14),
    [`@media (min-width: ${breakpoints.values.sm}px)`]: {
      fontSize: getRem(16),
    },
  },
  subtitle2: {
    lineHeight: "157%",
    letterSpacing: "0px",
    fontSize: getRem(12),
    [`@media (min-width: ${breakpoints.values.sm}px)`]: {
      fontSize: getRem(14),
    },
  },
  overline: {
    lineHeight: "266%",
    letterSpacing: 1,
    fontSize: getRem(10),
    textTransform: "uppercase",
    [`@media (min-width: ${breakpoints.values.sm}px)`]: {
      fontSize: getRem(12),
    },
  },
  caption: {
    lineHeight: "160%",
    letterSpacing: "0px",
    fontSize: getRem(10),
    [`@media (min-width: ${breakpoints.values.sm}px)`]: {
      fontSize: getRem(12),
    },
  },
};

export default typography;
