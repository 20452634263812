// eslint-disable-next-line import/prefer-default-export
export const openWebWidget = () => {
  // @ts-ignore : As We are using frontapp variable which is set dynamically from external script
  FrontChat("show");
};

export const getEnvironment = (): "dev" | "stage" | "prod" => {
  const projectsEnv = {
    default: "dev",
    "deep-thought-swyft-staging": "stage",
    "deepthought-swyft-dev": "dev",
    "deep-thought-swyft": "prod",
  };

  return projectsEnv[
    process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID || projectsEnv.default
  ];
};

export const getReturnmatesTrackingPageDomain = (trackingNumber: string) => {
  const env = getEnvironment();
  const returnmatesMode = env === "prod" ? "" : `${env}.`;

  return `https://${returnmatesMode}returnmates.com/tracking/${trackingNumber}`;
};
