import { Typography, styled, Stack, Link } from "@helo/ui";
import SwyayLogoPurpleSvg from "@helo/ui/assets/images/sway-logo-purple.svg";

import { BrandName } from "~/constants/brand";

const Container = styled("footer")(({ theme }) => ({
  margin: 0,
  padding: "25px",
  backgroundColor: theme.palette.neutralScale[100],
  textAlign: "center",
  display: "grid",
  placeContent: "center",
  justifyItems: "center",
}));

const Logo = styled("img")({
  width: "280px",
});

const Footer = () => (
  <Container>
    <Logo src={SwyayLogoPurpleSvg.src} alt={BrandName} />
    <Stack
      direction={{ xs: "column", sm: "row" }}
      spacing={{ xs: 1, sm: 2 }}
      sx={{ mt: 1, mb: 2 }}
    >
      <Link
        variant="caption"
        href="mailto:support@useswyft.com?subject=Support Inquiry"
        target="_blank"
      >
        Support
      </Link>
      {/* <Link
        variant="caption"
        href="https://www.useswyft.com/contact"
        target="_blank"
      >
        For Merchants & Carriers
      </Link>
      <Link
        variant="caption"
        href="https://www.useswyft.com/privacy-policy"
        target="_blank"
      >
        Privacy Policy
      </Link> */}
    </Stack>
    <Typography variant="caption" color="white" sx={{ mt: 1 }}>
      © Copyright {new Date().getFullYear()}
    </Typography>
  </Container>
);

export default Footer;
